import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generateClient } from "aws-amplify/api";
import { MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow, MDBTable, MDBTableBody, MDBTableHead, MDBIcon } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import { transformMoney } from "../../utils/money";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export default function AllCourses() {
    const { t, i18n } = useTranslation();
    const client = generateClient();
    const [isLoading, setIsLoading] = useState(true);
    const [allCourses, setAllCourses] = useState([]);

    const fetchAllCourses = async () => {
        setIsLoading(true);
        let courses = [];
        let nextToken = null;
        
        try {
            do {
                const { data, nextToken: newNextToken } = await client.graphql({
                    query: /* GraphQL */ `
                    query ListCourses(
                      $filter: ModelCourseFilterInput
                      $limit: Int
                      $nextToken: String
                    ) {
                      listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
                        items {
                          id
                          name {
                            language
                            text
                          }
                          type
                          price
                          sortKey
                          createdAt
                          updatedAt
                        }
                        nextToken
                      }
                    }
                    `,
                    variables: {
                        limit: 1000,
                        nextToken
                    }
                });
                courses = courses.concat(data.listCourses.items);
                nextToken = newNextToken;
            } while (nextToken);

            courses.sort((a, b) => (a.sortKey || 0) - (b.sortKey || 0));
            setAllCourses(courses);
        } catch (error) {
            console.error("Error fetching courses:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchAllCourses();
    }, []);

    const handleDeleteCourse = async (id) => {
        try {
            await client.graphql({
                query: /* GraphQL */ `
                mutation DeleteCourse($input: DeleteCourseInput!) {
                    deleteCourse(input: $input) {
                        id
                    }
                }
                `,
                variables: {
                    input: {
                        id
                    }
                }
            });
            setAllCourses(allCourses.filter(course => course.id !== id));
        } catch (error) {
            console.error("Error deleting course:", error);
        }
    };

    const onDragEnd = async (result) => {
        if (!result.destination) {
            return;
        }

        const items = Array.from(allCourses);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setAllCourses(items);

        // Update sortKey for affected items
        const startIndex = Math.min(result.source.index, result.destination.index);
        const endIndex = Math.max(result.source.index, result.destination.index);

        for (let i = startIndex; i <= endIndex; i++) {
            const course = items[i];
            try {
                await client.graphql({
                    query: /* GraphQL */ `
                    mutation UpdateCourse($input: UpdateCourseInput!) {
                        updateCourse(input: $input) {
                            id
                            sortKey
                        }
                    }
                    `,
                    variables: {
                        input: {
                            id: course.id,
                            sortKey: i
                        }
                    }
                });
            } catch (error) {
                console.error(`Failed to update sortKey for course ${course.id}:`, error);
            }
        }
    };

    return (
        <MDBRow>
            <MDBCol>
                <MDBCard>
                    <MDBCardBody>
                        <MDBCardTitle>
                            {t("All Courses")}
                            <Link to={`/admin/insight/course/create`}>
                                <MDBBtn className='mx-2 my-0 py-0' color='tertiary' rippleColor='light'>
                                    {t("Create course")}
                                </MDBBtn>
                            </Link>
                        </MDBCardTitle>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="courses">
                                {(provided) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                        <MDBTable align="middle">
                                            <MDBTableHead>
                                                <tr>
                                                    <th>{t("Name")}</th>
                                                    <th>{t("Type")}</th>
                                                    <th>{t("Price")}</th>
                                                    <th>{t("Actions")}</th>
                                                </tr>
                                            </MDBTableHead>
                                            <MDBTableBody>
                                                {allCourses.map((course, index) => (
                                                    <Draggable key={course.id} draggableId={course.id} index={index}>
                                                        {(provided) => (
                                                            <tr
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                            >
                                                                <td {...provided.dragHandleProps}>
                                                                    <MDBIcon fas icon='arrows-alt-v' className='me-2' style={{cursor: 'grab'}} />
                                                                    {course.name.find(n => n.language === i18n.language)?.text || course.name[0]?.text}
                                                                </td>
                                                                <td>{course.type}</td>
                                                                <td>{transformMoney(course.price)}</td>
                                                                <td>
                                                                    <Link to={`/admin/insight/course/${course.id}`}>
                                                                        <MDBBtn color='info' size='sm'>
                                                                            <MDBIcon fas icon='edit' />
                                                                        </MDBBtn>
                                                                    </Link>
                                                                    <MDBBtn color='danger' size='sm' onClick={() => handleDeleteCourse(course.id)}>
                                                                        <MDBIcon fas icon='trash-alt' />
                                                                    </MDBBtn>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </Draggable>
                                                ))}
                                            </MDBTableBody>
                                        </MDBTable>
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
        </MDBRow>
    );
}