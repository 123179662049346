import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { generateClient } from "aws-amplify/api"
import { MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow, MDBTable, 
    MDBTableBody, MDBTableHead, MDBBtnGroup, MDBIcon, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalBody, MDBModalTitle, MDBCardText, MDBCardImage,
    MDBCheckbox, MDBCollapse,
    MDBSelect,
    MDBInput
     } from "mdb-react-ui-kit"
import { Link } from "react-router-dom"
import { getUrl } from "aws-amplify/storage"


const client = generateClient()

export default function BookedBooths() {
    const { t, i18n } = useTranslation()

    const [events, setEvents] = useState([])
    const[booths, setBooths] = useState([])
    const[loading, setLoading] = useState(true)

    const [selectedBooth, setSelectedBooth] = useState(null)
    const [showBoothDetails, setShowBoothDetails] = useState(false)
    const [filters, setFilters] = useState({});

    const handleViewBooth = (id) => {
        setSelectedBooth(booths.find(booths => booths.id === id))
        setShowBoothDetails(true)
    }

    const getEvents = async () => {
        try {
            const events = await client.graphql({
                query: /* GraphQL */ `
                query ListEvents {
                    listEvents {
                        items {
                            id
                            name {
                                text
                                language
                            }
                            startDate
                            endDate
                            status
                        }
                    }
                }
                `,
                variables: {
                    filter: {
                        startDate: {
                            ge: new Date().toISOString().substring(0, 10)
                        }
                    }
                }
            });
            setEvents(events.data.listEvents.items.filter(event => event.status === "active" && new Date(event.startDate) >= new Date()));
            return events.data.listEvents.items;
        } catch (error) {
            console.log("error on fetching events", error);
        }
    }

    const getBooths = async (filters) => {

        try {
          let requestFilter = {};
          if (filters && Object.keys(filters).length > 0) {
            Object.keys(filters).forEach(key => {
              if (filters[key]) {
                requestFilter[key] = { eq: filters[key] };
              }
            });
          }
            const boothsRes = await client.graphql({
                query: /* GraphQL */ `
                query ListBookedBooths(
                  $filter: ModelBookedBoothFilterInput
                  $limit: Int
                  $nextToken: String
                ) {
                  listBookedBooths(filter: $filter, limit: $limit, nextToken: $nextToken) {
                    items {
                      id
                      orderLineItem {
                        id
                        product {
                            id
                            type
                            name {
                                language
                                text
                            }
                        }
                      }
                      event {
                        id
                        startDate
                        endDate
                        name {
                            language
                            text
                        }
                      }
                      boothNumber
                      company {
                        id
                        name
                        image {
                            main {
                                fileName
                            }
                        }
                      }
                      status
                      equipment {
                        items {
                            id
                            qty
                            data
                            files {
                                fileName
                                s3Path
                            }
                            orderLineItem {
                                id
                                product {
                                    id
                                    type
                                    subType
                                    name {
                                        language
                                        text
                                    }
                                    image {
                                        main {
                                            fileName
                                        }
                                    }
                                }
                            }
                        }
                      }
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                }
              `,
                variables: {
                    filter: requestFilter,
                    limit: 500
                }
            })
            //console.log("booths", boothsRes)
            // Set the booths to the state sorted alphabetically by the company name
            setBooths(boothsRes.data.listBookedBooths.items.sort((a, b) => a.company.name.localeCompare(b.company.name)))
            setLoading(false)
        } catch (error) {
            console.warn("error fetching booths", error)
        }
    }


    useEffect(() => {
        getEvents()
        getBooths()
    }, [])

    useEffect(() => {
        if (Object.keys(filters).length > 0) {
            getBooths(filters)
        }
    }, [filters])

    const downloadFile = async (key) => {
        try {
          const url = await getUrl({ 
            key,
            options: { level: 'protected' }

         });
          console.log('Download URL:', url);
          window.open(url.url, '_blank');
        } catch (error) {
          console.error('Error downloading file:', error);
        }
      };

    const [openEditBoothNumberBoothId, setOpenEditBoothNumberBoothId] = useState(null)
    const [openEditBoothNumberBoothNumber, setOpenEditBoothNumberBoothNumber] = useState(null)
    const updateBoothNumber = async (boothId, boothNumber) => {
        try {
            await client.graphql({
                query: /* GraphQL */ `
                mutation UpdateBookedBooth(
                  $input: UpdateBookedBoothInput!
                  $condition: ModelBookedBoothConditionInput
                ) {
                  updateBookedBooth(input: $input, condition: $condition) {
                    id
                    boothNumber
                  }
                }
              `,
                variables: {
                    input: {
                        id: boothId,
                        boothNumber
                    }
                }
            })
            setBooths(booths.map(booth => booth.id === boothId ? { ...booth, boothNumber } : booth))
        } catch (error) {
            console.warn("error updating booth number", error)
        }
    }

  return (
<>
<MDBRow>
    <MDBCol>
        <MDBCard>
            <MDBCardBody>
                <MDBCardTitle>{t("Booked Booths")}</MDBCardTitle>
                <MDBRow className="mt-2 mt-xl-3">
                    <MDBCol md="3">
                        <MDBSelect label={t("Event")}
                            onChange={event => setFilters({ ...filters, eventBookedBoothsId: event.value })}
                            value={filters.eventBookedBoothsId?.eq}
                            data={
                                [
                                    { text: t("All"), value: null },
                                    ...events.map(event => ({ text: event.name.find(n => n.language === i18n.language)?.text || event.name[0]?.text, value: event.id }))
                                ]
                            }
                        />
                    </MDBCol>
                </MDBRow>
                <MDBTable align="middle">
                    <MDBTableHead>
                        <tr>
                            <th>{t("Event")}</th>
                            <th>{t("Company")}</th>
                            <th>{t("Booth")}</th>
                            <th>{t("Graphics")}</th>
                            <th>{t("Floor Selection")}</th>
                            <th>{t("Booked Equipment")}</th>
                            <th>{t("Actions")}</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {
                            booths.map((booth, index) => (
                                <tr key={index}>
                                    <td>
                                       {booth.event.name.find(n => n.language === i18n.language)?.text || booth.event.name[0]?.text}
                                       
                                    </td>
                                    <td>
                                        <div className='d-flex align-items-center'>
                                        {booth.company.image?.main?.fileName ?
                                            <img
                                                src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/"+booth.company.image.main.fileName}
                                                className=""
                                                style={{ height: "50px", width: "50px", objectFit: "contain" }}
                                                alt={booth.company.image.alt}
                                            />
                                        :
                                            <MDBIcon className="" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="company-alt" />
                                                    
                                        }
                                        <div className='ms-3'>
                                        <p>{booth.company.name}</p>
                                        </div>
                                        </div>
                                       
                                    </td>
                                    <td>
                                        {booth.orderLineItem?.product.name.find(n => n.language === i18n.language)?.text || booth.orderLineItem?.product.name[0]?.text}
                                        <br />
                                        {t("Booth number")}: {booth.boothNumber}
                                        {openEditBoothNumberBoothId === booth.id ? (
                                            <div className="d-flex">
                                                <MDBInput
                                                    value={openEditBoothNumberBoothNumber}
                                                    onChange={e => setOpenEditBoothNumberBoothNumber(e.target.value)}
                                                />
                                                <MDBBtn
                                                    size="sm"
                                                    color="primary"
                                                    onClick={() => {
                                                        updateBoothNumber(openEditBoothNumberBoothId, openEditBoothNumberBoothNumber)
                                                        setOpenEditBoothNumberBoothId(null)
                                                        setOpenEditBoothNumberBoothNumber(null)
                                                    }}
                                                >
                                                    <MDBIcon fas icon="save" />
                                                </MDBBtn>
                                            </div>
                                        ) : (
                                            <span
                                               className="ms-2"
                                                onClick={() => {
                                                    setOpenEditBoothNumberBoothId(booth.id)
                                                    setOpenEditBoothNumberBoothNumber(booth.boothNumber)
                                                }}
                                            >
                                               <MDBIcon far icon="edit" />
                                                
                                            </span>
                                        )}
                                        <br></br>
                                       <span className="text-muted" style={{fontSize: "8px"}}>{booth.id}</span>
                                    </td>
                                    <td>
                                        {booth.equipment.items.filter(item => item.orderLineItem?.product.subType === "display_print").map((item, index) => (
                                            <div key={index}>
                                                {item.orderLineItem.product.name.find(n => n.language === i18n.language)?.text || item.orderLineItem.product.name[0]?.text}
                                                {item.data ?
                                                <MDBIcon className="text-success ms-2" fas icon='check' />
                                                :
                                                <MDBIcon className="text-danger ms-2" fas icon='times' />

                                                }
                                                
                                                {item.data && 
                                                 <>
                                                 <br />
                                                 <MDBBtn outline key={index} onClick={() => downloadFile(item.data)} color="primary" size="sm" className="ms-2">
                                                    {item.data}
                                                </MDBBtn>
                                                 </>
                                                }
                                            </div>
                                        ))}
                                    </td>
                                    <td>
                                        {booth.equipment.items.filter(item => item.orderLineItem?.product.subType === "carpet").map((item, index) => (
                                            <div key={index}>
                                                {item.data ?
                                                <MDBIcon className="text-success ms-2" fas icon='check' />
                                                :
                                                <MDBIcon className="text-danger ms-2" fas icon='times' />
                                                }
                                            </div>
                                        ))}
                                    </td>
                                    <td>
                                        {booth.equipment.items.filter(item => item.orderLineItem?.product && (item.orderLineItem.product?.subType !== "display_print" && item.orderLineItem.product?.subType !== "carpet")).map((item, index) => (
                                            <div key={index}>
                                                 {item.qty} x
                                                {item.orderLineItem.product.name.find(n => n.language === i18n.language)?.text.substring(0, 40) || item.orderLineItem.product.name[0]?.text.substring(0, 40)}
                                               
                                            </div>
                                        ))}
                                    </td>
                                    <td>
                                        {booth.orderLineItem?.product.type !== "event_booth_partner" && (
                                            <MDBBtnGroup>
                                            <Link to={`/mycompany/${booth.company.id}/edit-booth/${booth.id}`}>
                                            <MDBBtn size="sm" color="primary" >{t("View")}</MDBBtn>
                                            </Link>
                                        </MDBBtnGroup>
                                        )}
                                        
                                    </td>
                                </tr>
                            ))  
                        }
                    </MDBTableBody>
                </MDBTable>
            </MDBCardBody>
        </MDBCard>
    </MDBCol>
</MDBRow>


</>
  )
}
