// Lecture Type
// type Lecture
//   @model
//   @auth(rules: [
//     { allow: private, operations: [read] },
//     { allow: groups, groups: ["Admin, Moderator"], operations: [create, read, update, delete] }])
// {
//   id: ID!
//   startTime: AWSDateTime!
//   endTime: AWSDateTime!
//   event: Event @belongsTo
//   company: Company @hasOne
//   speakers: [Employee] @hasMany
//   session: String
//   room: String
//   title: [MultiLangString!]!
//   subjects: [MultiLangStringList]
//   content: MultiLangString
//   presentationLanguage: String
//   image: Image
//   pdfDe: File
//   pdfEn: File
// }

import { generateClient } from 'aws-amplify/api';
import { MDBBtn, MDBBtnGroup, MDBCard, MDBCardBody, MDBCardText, MDBCardTitle, MDBCol, MDBIcon, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBModalTitle, MDBRow, MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import lectureSchema from '../../../validation/LectureValidation';
import { isoToGermanDate } from "../../../utils/dateTools";
import { getUrl } from "aws-amplify/storage";
import ReactPlayer from 'react-player';
const client = generateClient();
export default function AllLectures({adminActions}) {
    const { t, i18n } = useTranslation();

    const companyid = window.location.pathname.includes("mycompany")
    ? window.location.pathname.split("/")[2]
    : window.location.pathname.split("/")[3];

    const [lectures, setLectures] = useState([]);

    const getLectures = async () => {
        try {
            const lectures = await client.graphql({
                variables: {
                    filter: {
                        companyLecturesId: {
                          eq: companyid
                        },
                        createdAt: {
                            gt: "2023-10-01T00:00:00.000Z"
                        },
                        type: {
                            regexp: "(marketing|technical|educational|marketing_wien|educational_wien|technical_wien)"

                        }
                       
                    }
                },
                query: /* GraphQL */ `
                query SearchLectures(
                  $filter: SearchableLectureFilterInput
                  $sort: [SearchableLectureSortInput]
                  $limit: Int
                  $nextToken: String
                  $from: Int
                  $aggregates: [SearchableLectureAggregationInput]
                ) {
                  searchLectures(
                    filter: $filter
                    sort: $sort
                    limit: $limit
                    nextToken: $nextToken
                    from: $from
                    aggregates: $aggregates
                  ) {
                    items {
                        title {
                            language
                            text
                        }
                        event {
                            id
                            name {
                                language
                                text
                            }
                        }
                        speakers {
                            items {
                                employee {
                                    image {
                                    fileName
                                    alt
                                    }
                                }
                            }
                        }
                        subjects {
                            id
                            name {
                                language
                                text
                            }
                        } 
                        topics {
                            items {
                              topic {
                                id
                                name {
                                  language
                                  text
                                }
                              }
                            }
                        }         
                        content {
                            language
                            text
                        }
                        conditions {
                            name
                            accepted
                        }
                        image {
                            fileName
                            alt
                        }
                        pdfDe {
                            url
                            name   
                        }
                        pdfEn {
                            url
                            name   
                        }
                        videoS3Key {
                            s3Path
                        }
                        company {
                            id
                            name
                            image {
                                main {
                                    fileName
                                    alt
                                }
                            }
                        }
                        videoProgress {
                            items {
                                id
                                userId
                                user {
                                  id
                                  title
                                  firstName
                                  lastName
                                  image {
                                    fileName
                                  }
                                  contact {
                                      address {
                                          company
                                      }
                                  }
                                }
                                videoId
                                progressInSeconds
                                totalWatchedInSeconds
                            }
                          }
                        id
                        session
                        adminComment
                        room
                        type
                        presentationLanguage
                        createdAt
                        updatedAt
                        companyLecturesId
                        eventLecturesId
                        startTime
                        endTime
                        lectureMainSpeakerId
                        __typename
                    }
                    nextToken
                    total
                    aggregateItems {
                      name
                      result {
                        ... on SearchableAggregateScalarResult {
                          value
                        }
                        ... on SearchableAggregateBucketResult {
                          buckets {
                            key
                            doc_count
                            __typename
                          }
                        }
                      }
                      __typename
                    }
                    __typename
                  }
                }
              `
            });
            setLectures(lectures.data.searchLectures.items.map(lecture => ({...lecture, topics: lecture.topics.items.map(topic => topic.topic)})).sort((a, b) =>  new Date(b.createdAt) - new Date(a.createdAt)));
        } catch (error) {
            console.log("error on fetching lectures", error);
            if(error.data?.searchLectures?.items) setLectures(error.data.searchLectures.items);
        }
    };

    useEffect(() => {
        getLectures();
        // document.addEventListener('contextmenu', event => {
        //     event.preventDefault();
        // });
        // return () => {
        //     document.removeEventListener('contextmenu', event => {
        //         event.preventDefault();
        //     });
        // }
    }, []);

    const [validationErrors, setValidationErrors] = useState([]);

    const validateLectures = (lectures) => {
        let errors = lectures.map((lecture) => {

            let lectureErrors = lectureSchema.validate(lecture).map((error) => ({
                field: error.path,
                message: error.message
            })).filter(e => e.message)

            // remove conditions
            lectureErrors = lectureErrors.filter((error) => error.message !== "Bedingungen");

            // Custom validation for conditions
            if(lecture.type === "marketing") {
                // Check image and speaker conditions
                // Image
                if(lecture.image?.fileName) {
                    const imageCondition = lecture.conditions?.find(c => c.name === "image");
                    if(!imageCondition || !imageCondition.accepted) {
                        lectureErrors.push({field: "image", message: "Bildrechte"})
                    }
                }
                // Speaker
                if(lecture.speakers?.items?.length > 0) {
                    const speakerCondition = lecture.conditions?.find(c => c.name === "speakers");
                    if(!speakerCondition || !speakerCondition.accepted) {
                        lectureErrors.push({field: "speaker", message: "Referenten-Bedingung"})
                    }
                }
               
                } else if (lecture.type === "technical" || lecture.type === "educational") {
                    if(!lecture.conditions) {
                        console.log("no conditions", lecture.id, lecture.conditions)

                    }
                    // Check image and speaker conditions and adCondition, recordCondition, rightsCondition, deadlineCondition
                    // Image
                    if(lecture.image?.fileName) {
                        const imageCondition = lecture.conditions?.find(c => c.name === "image");
                        if(!imageCondition || !imageCondition.accepted) {
                            lectureErrors.push({field: "image", message: "Bildrechte"})
                        }
                    }
                    // Speaker
                    if(lecture.speakers?.items?.length > 0) {
                        const speakerCondition = lecture.conditions?.find(c => c.name === "speakers");
                        if(!speakerCondition || !speakerCondition.accepted) {
                            lectureErrors.push({field: "speaker", message: "Referenten-Bedingung"})
                        }
                    }
                    // Ad
                    const adCondition = lecture.conditions?.find(c => c.name === "adCondition");
                    if(!adCondition || !adCondition.accepted) {
                        lectureErrors.push({field: "ad", message: "Bedingungen-Werbefrei"})
                    }
                    // Record
                    const recordCondition = lecture.conditions?.find(c => c.name === "recordCondition");
                    if(!recordCondition || !recordCondition.accepted) {
                        lectureErrors.push({field: "record", message: "Bedingungen-Aufzeichnung"})
                    }
                    // Rights
                    const rightsCondition = lecture.conditions?.find(c => c.name === "rightsContition");
                    if(!rightsCondition || !rightsCondition.accepted) {
                        lectureErrors.push({field: "rights", message: "Bedingungen-Aufzeichnungsrechte"})
                    }
                    // Deadline
                    const deadlineCondition = lecture.conditions?.find(c => c.name === "deadlineContition");
                    if(!deadlineCondition || !deadlineCondition.accepted) {
                        lectureErrors.push({field: "deadline", message: "Bedingungen-Deadline"})
                    }
                   
                }
            


            return ({
                    id: lecture.id,
                    errors: lectureErrors
                })
            });
        
        // errors.forEach((error) => {
        //     console.log(error)
        // })

        setValidationErrors(errors);
    }
    
    useEffect(() => {
        if(lectures.length > 0) {
            setTimeout(() => {
                validateLectures(lectures)
            }, 500);
        }
    }, [lectures]);


    const [deleteLectureModal, setDeleteLectureModal] = useState(false);
    const toggleDeleteLectureModal = () => setDeleteLectureModal(!deleteLectureModal);

    const [selectedLectureRemove, setSelectedLectureRemove] = useState(null);

    const deleteLecture = async (id) => {
        try {
            const deletedLecture = await client.graphql({
                variables: {
                    input: {
                        id: id
                    }
                },
                query: /* GraphQL */ `
                mutation DeleteLecture(
                  $input: DeleteLectureInput!
                  $condition: ModelLectureConditionInput
                ) {
                  deleteLecture(input: $input, condition: $condition) {
                    id
                  }
                }
              `
            });
            console.log("deleted lecture", deletedLecture);
            setLectures(lectures.filter((lecture) => lecture.id !== id));
            setSelectedLectureRemove(null);
            toggleDeleteLectureModal();
        } catch (error) {
            console.log("error on deleting lecture", error);
        }
    }


    // Video
    // Video Upload, View and Remove Modal
    const [videoModal, setVideoModal] = useState(false);
    const [selectedLectureVideo, setSelectedLectureVideo] = useState(null);
    const [selectedVideoUrl, setSelectedVideoUrl] = useState(null);
    const [lectureVideosUploadStatus, setLectureVideosUploadStatus] = useState({});
    const hanleOpenVideoModal = async (lectureId) => {
        setSelectedLectureVideo(lectureId);
        const videoKey = lectures.find(l => l.id === lectureId)?.videoS3Key?.s3Path;
        if (videoKey) {
            const videoUrl = await getVideoUrl(videoKey);
            setSelectedVideoUrl(videoUrl);
        }
        toggleVideoModal();
    }
    const toggleVideoModal = () => setVideoModal(!videoModal);

    const getVideoUrl = async (videoKey) => {
        try {
            const result = await getUrl({
                key: videoKey,
                level: 'public'
            });
            console.log("video url", result.url.href);
            return result.url.href;
        } catch (error) {
            console.log("error on getting video url", error);
        }
    }

    // Statistics Modal
    const [statisticsModal, setStatisticsModal] = useState(false);

    const handleOpenStatisticsModal = (lectureId) => {
        setSelectedLectureVideo(lectureId);
        setStatisticsModal(true);
    }
  

  return (
<>

<MDBRow className="mb-3 mb-lg-5">
    
    <MDBCol size="12" lg="5">
        <MDBCard className="mx-auto text-center">
            <MDBCardBody>
                <MDBCardTitle>
                    <h2>{t("Lounges 2025 Karlsruhe")}</h2>
                </MDBCardTitle>
                <h5>{t("Register Lectures for Lounges 2025 Karlsruhe now.")}</h5>
                <Link to={`/mycompany/${companyid}/register-lecture?eventId=81f8ba3d-61b2-48c0-8d99-d2b785920450`}>
                    <MDBBtn>{t("Register Lecture")}</MDBBtn>
                </Link>
            
            </MDBCardBody>
        </MDBCard>
    </MDBCol>
    {/* <MDBCol size="12" lg="5">
        <MDBCard className="mx-auto text-center h-100"  style={{backgroundColor: "#c0c0c0"}}>
            <MDBCardBody>
                <MDBCardTitle>
                    <h2>{t("Lounges 2025 Karlsruhe")}</h2>
                </MDBCardTitle>
                <h5>{t("Registration will start later this year")}</h5>
               
            
            </MDBCardBody>
        </MDBCard>
    </MDBCol> */}
</MDBRow>



<MDBCard>
    <MDBCardBody>
        <MDBCardTitle>
            <h2>{t("Lectures")}</h2>
        </MDBCardTitle>
        <MDBTable>
            <MDBTableHead>
                <tr>
                <th>{t("Event")}</th>
                    <th>{t("Title")}</th>
                    <th>{t("Type")}</th>
                    <th>{t("Speakers")}</th>
                    <th>{t("Date, Time & Room")}</th>
                    <th>{t("Status")}</th>
                    <th width="150">{t("Recording")}</th>
                    <th>{t("Actions")}</th>

                </tr>
            </MDBTableHead>
            <MDBTableBody>
                {lectures.length > 0 ? 
                    lectures.map((lecture) => (
                        <tr key={lecture.id}>
                        <td>
                            {lecture.event?.name && (lecture.event?.name.find(n => n.language === i18n.language)?.text || lecture.event?.name?.[0]?.text)}
                        </td>
                            <td>{lecture.title?.find(t => t.language === i18n.language)?.text || lecture.title?.[0]?.text}</td>
                            <td>{t(lecture.type)}</td>
                            <td>{lecture.speakers?.items?.map(speaker => <>
                            {speaker?.employee?.image?.fileName ?
                            <img
                                src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/${speaker.employee.image.fileName}`}
                                className="rounded-circle border"
                                style={{
                                    height: "50px",
                                    width: "50px",
                                    objectFit: "cover",
                                }}
                                alt={speaker.employee?.image.alt}
                            />
                            :
                            <MDBIcon
                                className="rounded-circle border"
                                style={{
                                    height: "50px",
                                    width: "50px",
                                    textAlign: "center",
                                    paddingTop: "12px",
                                    fontSize: "20px",
                                }}
                                fas
                                icon="user-alt"
                            />
                            }
                            </>)}</td>
                            <td>{lecture.startTime ? <>
                                {isoToGermanDate(lecture.startTime)}<br></br>
                                {lecture.startTime.substring(11, 16)} - {lecture.endTime.substring(11, 16)}<br></br>
                                {lecture.room ? t("Room") + ": " + lecture.room : t("not yet determined")}
                            </> : t("not yet determined")}</td>
                            <td>{
                                validationErrors.find(error => error.id === lecture.id)?.errors.length > 0 ?
                                    ( <div><p className="text-warning">{t("Incomplete")}</p>{[...new Set(validationErrors.find(error => error.id === lecture.id).errors.map(error => error.message))].join(", ")}</div>
                                    )
                                    :
                                    <p className="text-success">{t("Complete")}</p>
                                }
                                {lecture.adminComment && lecture.adminComment.length > 2  &&
                                    <p className="text-warning">{t("Admin Comment")}: <br/>{lecture.adminComment}</p>
                                }
                            </td>
                            <td>
                                {lecture.videoS3Key?.s3Path &&
                                    <>
                                    <MDBBtn color='link' className="px-2" onClick={() => hanleOpenVideoModal(lecture.id)}>{t("View recording")}</MDBBtn>
                                    <br/>
                                    <MDBBtn color='link' className="px-2" onClick={() => handleOpenStatisticsModal(lecture.id)}>{t("View statistics")}</MDBBtn>
                                    </>
                                }
                            </td>
                            <td>
                            <MDBBtnGroup shadow="0">
                            {/* <MDBBtn color='link' style={{color: "black"}} className="px-2" >{t("Upload Documents")}</MDBBtn> */}
                            {
                                adminActions ?
                                <Link to={`/admin/lecture/${companyid}/${lecture.id}`}>
                                    <MDBBtn color='link' className="px-2">{t("Edit")}</MDBBtn>
                                </Link>
                                :
                                <Link to={`/mycompany/${companyid}/register-lecture/${lecture.id}`}>
                                    <MDBBtn color='link' className="px-2">{t("Edit")}</MDBBtn>
                                </Link>
                            }
                           
                            <MDBBtn color='link' style={{color: "red"}} className="px-2" onClick={() => {setSelectedLectureRemove(lecture.id); toggleDeleteLectureModal()}}>{t("Delete")}</MDBBtn>
                            </MDBBtnGroup>
                            </td>
                        </tr>
                    ))
                :
                    <tr>
                        <td colSpan="7">
                            {t("No lectures found")}
                        </td>
                    </tr>
                }
            </MDBTableBody>
        </MDBTable>
    </MDBCardBody>
</MDBCard>
<MDBModal open={deleteLectureModal} onClose={() => setDeleteLectureModal(false)}>
    <MDBModalDialog centered size="sm">
        <MDBModalContent>
        <MDBModalHeader>
            <MDBModalTitle>{t("Remove lecture")}</MDBModalTitle>
            <MDBBtn className='btn-close' color='none' onClick={toggleDeleteLectureModal}></MDBBtn>
        </MDBModalHeader>
        <MDBModalBody className="text-center">
            <p>{t("Are you sure you want to remove this lecture?")}</p>
            <p className="fw-bold">{selectedLectureRemove && (lectures.find(l => l.id === selectedLectureRemove)?.title.find(t => t.language === i18n.language)?.text || lectures.find(l => l.id === selectedLectureRemove).title[0].text)}</p>
            <MDBBtn color='danger' onClick={() => deleteLecture(selectedLectureRemove)}>{t("Remove")}</MDBBtn>
        </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
<MDBModal open={videoModal} onClose={() => setVideoModal(false)}>
    <MDBModalDialog centered size="lg">
        <MDBModalContent>
            <MDBModalHeader>
                <MDBModalTitle>{t("View recording")}</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={() => {
                    setSelectedLectureVideo(null);
                    setSelectedVideoUrl(null);
                    toggleVideoModal();
                }}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody className="text-center">
                <h6>
                    {selectedLectureVideo && (lectures.find(l => l.id === selectedLectureVideo)?.title.find(t => t.language === i18n.language)?.text || lectures.find(l => l.id === selectedLectureVideo).title[0].text)}
                </h6>
                <MDBCardText className="text-start">
                <span className="fw-bold">Vortrags-Video zur Kontrolle bis 10. Mai 2024</span>
                <br/>
                Liebe Referenten und Admins,<br/>
                bitte schauen Sie sich das Video an. Bitte prüfen Sie die Videos gründlich, um sicherzustellen, dass sie werbefrei sind und keine inhaltlichen Fehler enthalten. Bitte notieren Sie alle Fehler, die Sie finden, und senden Sie Ihre Korrekturvorschläge per E-Mail an martin@i-ec.de bis 10. Mai 2024. 
                <br/>
                Beachten Sie bitte, dass der Veröffentlichung bereits zugestimmt haben und wir ausschließlich inhaltliche Fehler korrigieren. <br/>
                Sollte das Vortrags-Video Produkt-Werbung enthalten, nehmen wir uns die Freiheit, das Video nicht in den Streamingbereich zu übernehmen.
                </MDBCardText>
                {
                    selectedVideoUrl &&
                    <ReactPlayer
                        url={selectedVideoUrl}
                        controls
                        width="100%"
                        height="auto"
                        config={{
                            file: {
                                attributes: {
                                    controlsList: "nodownload",
                                    onContextMenu: e => e.preventDefault()
                                }
                            }
                        }}
                    />

                }
            </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
<MDBModal open={statisticsModal} onClose={() => setStatisticsModal(false)}>
    <MDBModalDialog centered size="lg">
        <MDBModalContent>
            <MDBModalHeader>
                <MDBModalTitle>{t("View statistics")}</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={() => setStatisticsModal(false)}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody className="text-center">
                <MDBTable>
                    <MDBTableHead>
                        <tr>
                            <th>{t("User")}</th>
                            <th>{t("Watched Length")}</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {lectures.find(l => l.id === selectedLectureVideo)?.videoProgress.items.map((videoProgress) => (
                            <tr key={videoProgress.id}>
                                <td>
                                    <div className='d-flex align-items-center'>
                                        {videoProgress.user?.image?.fileName ?
                                            <img
                                                src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/"+videoProgress.user.image.fileName}
                                                className="rounded-circle border"
                                                style={{ height: "50px", width: "50px", objectFit: "contain" }}
                                                alt={videoProgress.user.firstName + " " + videoProgress.user.lastName}
                                            />
                                        :
                                            <MDBIcon className="" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="user" />
                                                    
                                        }
                                        <div className='ms-3'>
                                            <p className='fw-bold mb-1'>{(videoProgress.user?.title ? videoProgress.user.title + " " : "") + videoProgress.user?.firstName + " " + videoProgress.user?.lastName}</p>
                                            <p className='text-muted mb-0'>{videoProgress.user?.contact?.address?.company || ""}</p>
                                        </div>
                                    </div>
                                </td>
                                <td>{videoProgress.totalWatchedInSeconds} {t("Seconds")}</td>
                            </tr>
                        ))}
                    </MDBTableBody>
                </MDBTable>
            </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
</>
  )
}
