import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { generateClient } from "aws-amplify/api"
import { MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow, MDBTable, 
    MDBTableBody, MDBTableHead, MDBBtnGroup, MDBIcon, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalBody, MDBModalTitle, MDBCardText, MDBCardImage,
    MDBCheckbox, MDBCollapse,
    MDBSelect
     } from "mdb-react-ui-kit"
import { Link } from "react-router-dom"


const client = generateClient()

export default function AdminAllProducts() {
    const { t, i18n } = useTranslation()

    const[products, setProducts] = useState([])
    const[loading, setLoading] = useState(true)

    const [selectedProduct, setSelectedProduct] = useState(null)
    const [showProductDetails, setShowProductDetails] = useState(false)
    const [filters, setFilters] = useState({});

    const handleViewProduct = (productId) => {
        setSelectedProduct(products.find(product => product.id === productId))
        setShowProductDetails(true)
    }



    const getProducts = async (filters) => {

        try {
          let requestFilter = {};
          if (filters && Object.keys(filters).length > 0) {
            Object.keys(filters).forEach(key => {
              if (filters[key]) {
                requestFilter[key] = { eq: filters[key] };
              }
            });
          }
            const products = await client.graphql({
                query: /* GraphQL */ `
                query ListProducts(
                  $filter: ModelProductFilterInput
                  $limit: Int
                  $nextToken: String
                ) {
                  listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
                    items {
                      id
                      name {
                        language
                        text
                        __typename
                      }
                      invoiceName {
                        language
                        text
                        __typename
                      }
                      description {
                        language
                        text
                        __typename
                      }
                      shortDescription {
                        language
                        text
                        __typename
                      }
                      includes {
                        language
                        text
                      }
                      sku
                      stock
                      orderLineItems {
                        items {
                          qty
                        }
                      }
                      ean
                      type
                      price
                      image {
                        main {
                            fileName
                            alt
                            __typename
                        }
                        __typename
                      }
                      features {
                        __typename
                      }
                      files {
                        url
                        s3Path
                        fileName
                        type
                        name
                        __typename
                      }
                      allowedBuyerGroups
                      requiredShipping
                      icon
                      createdAt
                      updatedAt
                      bundleBoothAvaiableAccessoriesId
                      bundleBoothIncludedAccessoriesId
                      __typename
                    }
                    nextToken
                    __typename
                  }
                }
              `,
                variables: {
                    filter: requestFilter,
                    limit: 1000
                }
            })
            console.log("products", products)
            setProducts(products.data.listProducts.items.sort((a, b) => a.name[0].text.localeCompare(b.name[0].text)))
            setLoading(false)
        } catch (error) {
            console.warn("error fetching products", error)
        }
    }


    useEffect(() => {
        getProducts()
    }, [])

    useEffect(() => {
        if (Object.keys(filters).length > 0) {
            getProducts(filters)
        }
    }, [filters])




    const handleDeleteProduct = async (productId) => {
        try {
            await client.graphql({
                query: /* GraphQL */ `
                mutation DeleteProduct($input: DeleteProductInput!) {
                  deleteProduct(input: $input) {
                    id
                  }
                }
              `,
                variables: {
                    input: {
                        id: productId
                    }
                }
            })
            setProducts(products.filter(product => product.id !== productId))
        } catch (error) {
            console.warn("error deleting product", error)
        }
    }

  return (
<>
<MDBRow>
    <MDBCol>
        <MDBCard>
            <MDBCardBody>
                <MDBCardTitle>{t("All Products")}<Link to={`/admin/product/create`}><MDBBtn className='mx-2 my-0 py-0' color='tertiary' rippleColor='light'>{t("Create product")}</MDBBtn></Link></MDBCardTitle>
                <MDBRow className="mt-2 mt-xl-3">
                  <MDBCol md="3">
                      <MDBSelect label={t("Type")}
                          onChange={type => setFilters({ ...filters, type: type.value })}
                          value={filters.type?.eq}
                          data={
                              [
                                  { text: t("All"), value: null },
                                  { text: t("Event booths"), value: "event_booth" },
                                  { text: t("Event spaces"), value: "event_space" },
                                  { text: t("Event booths partner"), value: "event_booth_partner" },
                                  { text: t("Event equipment"), value: "event_equipment" },
                                  { text: t("Marketing"), value: "marketing" },
                                  { text: t("Marketing Stand"), value: "marketing_booth" },
                                  { text: t("Digital Access"), value: "digital_access" },
                                  { text: t("Tickets"), value: "ticket" },
                                  { text: t("Discount"), value: "discount" },
                              ]
                          }
                      />
                  </MDBCol>
              </MDBRow>
                <MDBTable align="middle">
                    <MDBTableHead>
                        <tr>
                            <th>{t("Image")}</th>
                            <th>{t("Name")}</th>
                            <th>{t("SKU")}</th>
                            <th>
                                {t("Stock")}
                                <br />
                                <span style={{fontSize: "11px"}}>{t("Total")} | {t("Ordered")} | {t("Available")}</span>
                                </th>
                            <th>{t("Actions")}</th>
                            
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {products?.map((product, index) => (
                            <tr key={index}>
                                <td>
                                <div className='d-flex align-items-center'>
                                    {product.image?.main?.fileName ?
                                        <img
                                            src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/ProductImages/"+product.image.main.fileName}
                                            className=""
                                            style={{ height: "50px", width: "50px", objectFit: "contain" }}
                                            alt={product.image.alt}
                                        />
                                    :
                                        <MDBIcon className="" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="company-alt" />
                                                
                                    }
                                </div>
                                </td>
                                <td>{product.name.find(n => n.language === i18n.language)?.text || product.name[0]?.text}</td>
                                <td>{product.sku}</td>
                                <td>
                                <span>{product.stock + product.orderLineItems?.items.reduce((acc, item) => acc + item.qty, 0)}</span> | <span>{product.orderLineItems?.items.reduce((acc, item) => acc + item.qty, 0)}</span> | <span className="fw-bold">{product.stock}</span> 
                                    <br />
                                   
                                    </td>
                                <td>
                                    <MDBBtnGroup>
                                        <MDBBtn color='info' size='sm' onClick={() => handleViewProduct(product.id)}><MDBIcon fas icon='eye' /></MDBBtn>
                                        <Link to={`/admin/product/${product.id}`}><MDBBtn color='info' size='sm' onClick={() => console.log("edit", product)}><MDBIcon fas icon='edit' /></MDBBtn></Link>
                                        <MDBBtn color='danger' size='sm' onClick={() => handleDeleteProduct(product.id)}><MDBIcon fas icon='trash-alt' /></MDBBtn>
                                    </MDBBtnGroup>
                                </td>
                            </tr>
                        ))}
                    </MDBTableBody>
                </MDBTable>
            </MDBCardBody>
        </MDBCard>
    </MDBCol>
</MDBRow>
<MDBModal open={showProductDetails} onClose={() => setShowProductDetails(false)}>
    <MDBModalDialog scrollable size="xl">
        <MDBModalContent>
            <MDBModalHeader>
                <MDBModalTitle>{t("Product Details")}</MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
                <MDBCard>
                    <MDBCardBody>
                        <MDBCardTitle>{selectedProduct?.name.find(n => n.language === i18n.language)?.text || selectedProduct?.name[0]?.text}</MDBCardTitle>
                        <MDBCardText>{selectedProduct?.description?.find(n => n.language === i18n.language)?.text || selectedProduct?.description?.[0]?.text}</MDBCardText>
                        <MDBCardImage src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/ProductImages/"+selectedProduct?.image?.main?.fileName} alt={selectedProduct?.image?.main?.alt} position='top' />
                        <MDBIcon fas icon={selectedProduct?.icon} />
                        <MDBCardText>{t("SKU")}: {selectedProduct?.sku}</MDBCardText>
                        {/* <MDBCardText>{t("EAN")}:{selectedProduct?.ean}</MDBCardText> */}
                        <MDBCardText>{t("Type")}: {selectedProduct?.type}</MDBCardText>
                        <MDBCardText>{t("Price")}: {selectedProduct?.price} €</MDBCardText>
                        {selectedProduct?.allowedBuyerGroups &&<MDBCardText>{t("Allowed buyer groups")}: {selectedProduct?.allowedBuyerGroups}</MDBCardText>}
                        <ul>
                        {selectedProduct?.includes?.filter(i => i.language === i18n.language).map((include, index) => (
                            <li key={index}>{include?.text}</li>
                        ))}
                        </ul>
                        <MDBCardText>{selectedProduct?.shortDescription.find(n => n.language === i18n.language)?.text || selectedProduct?.shortDescription[0]?.text}</MDBCardText>
                        <MDBCardText>{selectedProduct?.features}</MDBCardText>
                        
                        <MDBCardText>{selectedProduct?.requiredShipping}</MDBCardText>
                       
                    </MDBCardBody>
                </MDBCard>
            </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>

</>
  )
}
