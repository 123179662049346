import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBListGroup, MDBListGroupItem, MDBSpinner, MDBIcon, MDBProgress, MDBProgressBar, MDBCardFooter, MDBCardImage } from 'mdb-react-ui-kit';
import { generateClient } from 'aws-amplify/api';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { UserContext } from '../../App';

const client = generateClient();

const CourseDetail = () => {
  const { courseId } = useParams();
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const { t, i18n } = useTranslation();

  const [course, setCourse] = useState(null);
  const [videoProgresses, setVideoProgresses] = useState([]);

  useEffect(() => {
    fetchCourseDetails();
  }, [courseId]);

  const fetchCourseDetails = async () => {
    try {
      const courseData = await client.graphql({
        query: /* GraphQL */ `
          query GetCourse($id: ID!) {
            getCourse(id: $id) {
              id
              name {
                language
                text
              }
              shortDescription {
                language
                text
              }
              description {
                language
                text
              }
              topics {
                language
                text
              }
              speakersDescription {
                language
                text
              }
              CTADescription {
                language
                text
              }
              CTABulletPoints {
                text {
                  language
                  text
                }
              }
              lectures {
                items {
                  lecture {
                    id
                    lengthInSeconds
                    title {
                      language
                      text
                    }
                    company {
                      name
                      image {
                        main {
                          fileName
                        }
                      }
                    }
                    image {
                      fileName
                      alt
                    }
                    speakers {
                      items {
                        employee {
                          user {
                            firstName
                            lastName
                          }
                          image {
                            fileName
                            alt
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        variables: { id: courseId }
      });
      setCourse(courseData.data.getCourse);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching course details:", error);
      setIsLoading(false);
    }
  };

  const getVideoProgress = async (lectureId) => {
    try {
      const videoProgressData = await client.graphql({
        query: /* GraphQL */ `
        query VideoProgressByVideoAndUser(
          $videoId: String!
          $userId: ModelStringKeyConditionInput
        ) {
          videoProgressByVideoAndUser(
            videoId: $videoId
            userId: $userId
          ) {
            items {
              id
              userId
              videoId
              progressInSeconds
              totalWatchedInSeconds
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
        }
      `,
        variables: { userId: {eq: user.sub}, videoId: lectureId }
      });
      return videoProgressData.data.videoProgressByVideoAndUser.items[0];
    } catch (error) {
      console.error("Error fetching video progress:", error);
    }
  };

  useEffect(() => {
    if (course) {
      const fetchVideoProgresses = async () => {
        const videoProgresses = await Promise.all(course.lectures.items.map(async (lectureItem) => {
          const lecture = lectureItem.lecture;
          const videoProgress = await getVideoProgress(lecture.id);
          if(videoProgress) {
           return { lectureId: lecture.id, videoProgress };
          } else {
            return { lectureId: lecture.id, videoProgress: { progressInSeconds: 100 } };
          }
        }));
        setVideoProgresses(videoProgresses);
      };
      fetchVideoProgresses();
    }
  }, [course]);

  


  const getLocalizedText = (textArray, field) => {
    const localizedText = textArray?.find(item => item.language === i18n.language);
    return localizedText ? localizedText.text : textArray?.[0]?.[field] || '';
  };

  if (isLoading) {
    return (
      <MDBContainer className="text-center my-5">
        <MDBSpinner role="status">
          <span className="visually-hidden">{t("Loading")}</span>
        </MDBSpinner>
      </MDBContainer>
    );
  }

  if (!course) {
    return <MDBContainer className="text-center my-5">{t("Error loading course")}</MDBContainer>;
  }

  return (
    <MDBContainer className="my-5">
      <MDBRow>
        <MDBCol>
          <MDBCard>
            <MDBCardBody>
              <MDBCardTitle>{getLocalizedText(course.name, 'text')}</MDBCardTitle>
              <MDBCardText>{getLocalizedText(course.shortDescription, 'text')}</MDBCardText>
              {course.speakersDescription?.length > 0 && <MDBCardText>{t("Speakers")}: {getLocalizedText(course.speakersDescription, 'text')}</MDBCardText>}
              <MDBCardText>{parse(getLocalizedText(course.description, 'text'))}</MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>

      <MDBRow className="mt-4">
  <MDBCol>
    <h3>{t("Lectures")}</h3>
    <MDBRow>
      {course.lectures.items.map((lectureItem) => {
        const lecture = lectureItem.lecture;
        const videoProgress = videoProgresses.find((vp) => vp.lectureId === lecture.id);
        const progressPercentage = videoProgress?.videoProgress?.progressInSeconds && lecture.lengthInSeconds
          ? Math.round((videoProgress.videoProgress.progressInSeconds / lecture.lengthInSeconds) * 100) 
          : 0;

        return (
          <MDBCol key={lecture.id} size="12" md="6" lg="4" className="mb-4">
            <Link to={`/academy/basics/lecture/${lecture.id}`} className="text-reset text-decoration-none">
              <MDBCard className="h-100">
                <MDBCardImage
                  src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/lectureImages/${lecture.image.fileName}`}
                  alt={lecture.image.alt}
                  position="top"
                  style={{ height: '200px', objectFit: 'cover' }}
                />
                <MDBCardBody>
                  <MDBCardTitle>{getLocalizedText(lecture.title, 'text')}</MDBCardTitle>
              
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      {lecture.speakers.items.map((speaker, index) => (
                        <img
                          key={index}
                          src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/${speaker.employee.image.fileName}`}
                          alt={speaker.employee.image.alt}
                          style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '5px' }}
                          title={`${speaker.employee.user.firstName} ${speaker.employee.user.lastName}`}
                        />
                      ))}
                    </div>
                    <div>
                      <img
                        src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/${lecture.company.image.main.fileName}`}
                        alt={lecture.company.image.main.fileName}
                        style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '5px' }}
                      />
                      <small>{lecture.company.name}</small>
                    </div>
                  </div>

                  
                </MDBCardBody>
                <MDBCardFooter>
                  <MDBProgress height="10">
                    <MDBProgressBar
                      bgColor='success'
                      width={progressPercentage}
                      valuemin={0}
                      valuemax={100}
                    />
                  </MDBProgress>
                  <small className="mt-2 d-block">
                    <span className="fw-bold">{progressPercentage}%</span> {t("complete")}
                  </small>
                </MDBCardFooter>
              </MDBCard>
            </Link>
          </MDBCol>
        );
      })}
    </MDBRow>
  </MDBCol>
</MDBRow>
    </MDBContainer>
  );
};

export default CourseDetail;