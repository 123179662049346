import React, { useState, useCallback, useEffect, useContext } from 'react';
import {
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBSideNavCollapse,
  MDBIcon,
  MDBContainer,
  MDBNavbar,
  MDBInput,
  MDBInputGroup,
  MDBNavbarToggler,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBBadge,
  MDBCollapse,
} from 'mdb-react-ui-kit';
import { Link } from "react-router-dom";
import { signOut } from 'aws-amplify/auth';
import { useTranslation } from "react-i18next";
import LangSelect from "./components-user/LangSelect";
import { UserContext } from "./App";


export default function Navigation(props) {
    const { t, i18n } = useTranslation();
    const { user} = useContext(UserContext);

    const [collapseOpened, setCollapseOpened] = useState('accordionCollapse1');
    const [basicOpen, setBasicOpen] = useState(false);
    const [mode, setMode] = useState('side');
    const [showBasic, setShowBasic] = useState(false);

    const handleSetOpen = (value) => {
        setBasicOpen(value);
        props.setDesktopFullscreen(!value);
    };

    const toggleAccordion = (value) => {
        value !== collapseOpened ? setCollapseOpened(value) : setCollapseOpened('');
    };

    const handleResize = useCallback(() => {
        if (!window.matchMedia('screen and (min-width: 992px)').matches) {
            setMode('side');
            return setBasicOpen(false);
        }
        setMode('push');
        return setBasicOpen(true);
    }, []);

    useEffect(() => {
        handleResize();
    }, [handleResize]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, [handleResize]);


return (
    <>
    <header>
    <MDBSideNav backdrop={false} mode={mode} open={basicOpen} getOpenState={(e) =>  setBasicOpen(e)} bgColor='primary'>
        <div className='d-flex justify-content-center py-4'>
        <img
            id='MDB-logo'
            src='/logo_neg_cp.svg'
            alt='Cleanroom Processes'
            draggable='false'
            height={"65px"}
        />
        </div>
        <h5 className="text-center">{t("Management Console")}</h5>
        <MDBSideNavMenu>
        <MDBSideNavItem>
            <MDBSideNavLink>
            <MDBIcon fas icon="home me-3" />
            {t("Dashboard")}
            </MDBSideNavLink>
        </MDBSideNavItem>
        <MDBSideNavItem>
            <hr className="mx-3 my-0"></hr>
        </MDBSideNavItem>
        <MDBSideNavItem>
            <MDBSideNavLink
            icon='angle-down'
            shouldBeExpanded={collapseOpened === 'accordionCollapse1'}
            onClick={() => toggleAccordion('accordionCollapse1')}
            >
            <MDBIcon icon='users' className='fas me-3' />
            {t("Users & Visitors")}
            </MDBSideNavLink>
            <MDBSideNavCollapse id='accordionCollapse1' open={collapseOpened === 'accordionCollapse1'}>
            <Link to="/admin/users" className="ripple ripple-surface sidenav-link">{t("Platform Users")}</Link>
            <MDBSideNavLink href="/admin/users">{t("Visitors")}</MDBSideNavLink>
            <MDBSideNavLink>{t("Admins & Moderators")}</MDBSideNavLink>
            <MDBSideNavLink>{t("Deletion Requests")}</MDBSideNavLink>
            </MDBSideNavCollapse>
        </MDBSideNavItem>
        <MDBSideNavItem>
            <Link to="/admin/companies" className="ripple ripple-surface sidenav-link">
                <MDBIcon icon='building' className='fas me-3' />
                {t("Companys")}
            </Link>
        </MDBSideNavItem>
        <MDBSideNavItem>
            <MDBSideNavLink
            icon='angle-down'
            shouldBeExpanded={collapseOpened === 'accordionCollapse2'}
            onClick={() => toggleAccordion('accordionCollapse2')}
            >
            <MDBIcon icon='calendar-day' className='fas me-3' />
            {t("Events")}
            </MDBSideNavLink>
            <MDBSideNavCollapse id='accordionCollapse2' open={collapseOpened === 'accordionCollapse2'}>
            <Link to="/admin/events" className="ripple ripple-surface sidenav-link">{t("Events")}</Link>
            <Link to="/admin/booked-booths" className="ripple ripple-surface sidenav-link">{t("Booked Booths")}</Link>
            <MDBSideNavLink href='/admin/lectures'>{t("Lectures")}</MDBSideNavLink>
            <MDBSideNavLink>{t("Furnishing & Equipment")}</MDBSideNavLink>
            </MDBSideNavCollapse>
        </MDBSideNavItem>
        <MDBSideNavItem>
            <MDBSideNavLink
            icon='angle-down'
            shouldBeExpanded={collapseOpened === 'accordionCollapseinsight'}
            onClick={() => toggleAccordion('accordionCollapseinsight')}
            >
            <MDBIcon icon='graduation-cap' className='fas me-3' />
            Academy
            </MDBSideNavLink>
            <MDBSideNavCollapse id='accordionCollapseinsight' open={collapseOpened === 'accordionCollapseinsight'}>
            <Link to="/admin/insight/courses" className="ripple ripple-surface sidenav-link">{t("Basic Courses")}</Link>
            <Link to="/admin/insight/courses" className="ripple ripple-surface sidenav-link">{t("Live Seminars")}</Link>
            <Link to="/admin/video-progress" className="ripple ripple-surface sidenav-link">{t("Video Views")}</Link>
            </MDBSideNavCollapse>
        </MDBSideNavItem>
        <MDBSideNavItem>
            <Link to="/admin/products" className="ripple ripple-surface sidenav-link">
                <MDBIcon icon='archive' className='fas me-3' />
                {t("Products")}
            </Link>
        </MDBSideNavItem>
        <MDBSideNavItem>
            <MDBSideNavLink
            icon='angle-down'
            shouldBeExpanded={collapseOpened === 'accordionCollapse3'}
            onClick={() => toggleAccordion('accordionCollapse3')}
            >
            <MDBIcon icon='file-invoice' className='fas me-3' />
            {t("Accounting")}
            </MDBSideNavLink>
            <MDBSideNavCollapse id='accordionCollapse3' open={collapseOpened === 'accordionCollapse3'}>
            <MDBSideNavLink>{t("All Invoices")}</MDBSideNavLink>
            <MDBSideNavLink>{t("Invoice Settings")}</MDBSideNavLink>
            </MDBSideNavCollapse>
        </MDBSideNavItem>
        <MDBSideNavItem>
            <Link
                to="/admin/orders
                "
                className="ripple ripple-surface sidenav-link"
            >
                <MDBIcon icon="shopping-bag" className="fas me-3" />
                {t("Buchungen")}
            </Link>
        </MDBSideNavItem>
        <MDBSideNavItem>
            <Link
                to="/admin/tickets
                "
                className="ripple ripple-surface sidenav-link"
            >
                <MDBIcon icon="ticket" className="fas me-3" />
                {t("Tickets")}
            </Link>
        </MDBSideNavItem>
        <MDBSideNavItem>
            <Link to="/admin/lecture_scheduling" className="ripple ripple-surface sidenav-link">
                <MDBIcon icon='building' className='fas me-3' />
                Event Kalendar
            </Link>
        </MDBSideNavItem>
        
        <MDBSideNavItem>
            <Link to="/admin/lectures" className="ripple ripple-surface sidenav-link">
                <MDBIcon icon='building' className='fas me-3' />
                {t("Lectures")}
            </Link>
        </MDBSideNavItem>
        <MDBSideNavItem>
            <Link to="/admin/news" className="ripple ripple-surface sidenav-link">
                <MDBIcon icon='newspaper' className='fas me-3' />
                {t("News")}
            </Link>
        </MDBSideNavItem>
        <MDBSideNavItem>
            <Link to="/companies-attending-event" className="ripple ripple-surface sidenav-link">
                <MDBIcon icon='building' className='fas me-3' />
                Unternehmen LNG2024
            </Link>
        </MDBSideNavItem>
        <MDBSideNavItem>
            <Link to="/admin/printticket" className="ripple ripple-surface sidenav-link">
                <MDBIcon icon='ticket' className='fas me-3' />
                Print Tickets
            </Link>
        </MDBSideNavItem>
        </MDBSideNavMenu>
        <div className="d-inline d-md-none fixed-bottom">
                <LangSelect />
        </div>
        <MDBIcon
            fas
            // className="d-lg-none"
            icon="angle-double-left"
            onClick={() => {handleSetOpen(!basicOpen)}}
            style={{
                position: "fixed",
                top: "1rem",
                right: "1rem"
            }}
        />
        <>
        <div
            className={"d-lg-none " + (basicOpen ? "sidenav-overlay" : "sidenav-overlay d-none")}
            style={{
                width: "100vw",
                height: "100vh",
                position: "fixed",
                top: "0",
                left: "240px",
            }}
            onClick={() => {handleSetOpen(!basicOpen)}}>
        </div>
        </>
       


    </MDBSideNav>
    

    <MDBNavbar fixed='top' expand='lg' id='main-navbar' bgColor='secondary' style={{minHeight: "3.3rem"}}>
        <MDBContainer fluid>
        <MDBNavbarToggler
        className="d-flex"
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'
            onClick={() => handleSetOpen(!basicOpen)}
        >
            <MDBIcon icon='bars' fas />
        </MDBNavbarToggler>

        <MDBInputGroup className='d-flex justify-content-center my-auto mainSearch'>
            <MDBInput className="bg-light" wrapperClass="mainSearch_InputWrapper" label={t("Search")} />
        </MDBInputGroup>

        <MDBCollapse navbar open={true} className="d-flex ms-2 ms-md-0"  style={{maxHeight: "50px"}}>
            <MDBNavbarNav right fullWidth={false}>
            {/* <MDBNavbarItem>
                <MDBDropdown>
                <MDBDropdownToggle tag='a' className='nav-link text-dark'>
                    <MDBIcon icon='bell' />
                    <MDBBadge notification color='danger' pill>
                    1
                    </MDBBadge>
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                    <MDBDropdownItem link>Action
                    </MDBDropdownItem>
                    <MDBDropdownItem link>Another action
                    </MDBDropdownItem>
                    <MDBDropdownItem link>Something else here
                    </MDBDropdownItem>
                </MDBDropdownMenu>
                </MDBDropdown>
            </MDBNavbarItem> */}
            <MDBNavbarItem className="d-none d-md-inline">
                <LangSelect />
            </MDBNavbarItem>
            <MDBNavbarItem>
                <MDBDropdown>
                <MDBDropdownToggle tag='a' className='nav-link'>
                    Admin
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                    <MDBDropdownItem link onClick={() => props.setAdminAsUser(true)}>Switch to User</MDBDropdownItem>
                    <MDBDropdownItem link>My profile
                    </MDBDropdownItem>
                    <MDBDropdownItem link>Settings
                    </MDBDropdownItem>
                    <MDBDropdownItem link onClick={() => signOut()}>Logout
                    </MDBDropdownItem>
                </MDBDropdownMenu>
                </MDBDropdown>
            </MDBNavbarItem>
            </MDBNavbarNav>
        </MDBCollapse>
        </MDBContainer>
    </MDBNavbar>
    </header>
    </>
);
}